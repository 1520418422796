
import {Injectable} from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError, from } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';

import { AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(private storage: Storage,private authenticationService:AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // YOU CAN ALSO DO THIS
    // const token = this.authenticationService.getToke()

    return from(this.storage.get("access_token"))
        .pipe(
            switchMap(token => {
                if (token) {
                    request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
                }

                if (!request.headers.has('Content-Type')) {
                    request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
                }

                return next.handle(request).pipe(
                    map((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            // do nothing for now
                        }
                        return event;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        const status =  error.status;
                        if (error.status === 401) {
                            // auto logout if 401 response returned from api
                            this.authenticationService.logout();
                        }
                        const reason = error && error.error.reason ? error.error.reason : '';

                        //this.presentAlert(status, reason);
                        return throwError(error);
                    })
                );
            })
        );


}
}
