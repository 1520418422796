import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'workinghours', loadChildren: './workinghours/workinghours.module#WorkinghoursPageModule' },
  { path: 'help', loadChildren: './help/help.module#HelpPageModule' },
  { path: 'view-help', loadChildren: './help/view-help/view-help.module#ViewHelpPageModule' },
  { path: 'contacts', loadChildren: './contacts/contacts.module#ContactsPageModule' },
  { path: 'evidence', loadChildren: './evidence/evidence.module#EvidencePageModule' },
  { path: 'account', loadChildren: './account/account.module#AccountPageModule' },
  { path: 'edit-working-hour', loadChildren: './workinghours/edit-working-hour/edit-working-hour.module#EditWorkingHourPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'add-working-hour', loadChildren: './workinghours/add-working-hour/add-working-hour.module#AddWorkingHourPageModule' },
  { path: 'materials', loadChildren: './materials/materials.module#MaterialsPageModule' },
  { path: 'materials-add', loadChildren: './materials/materials-add/materials-add.module#MaterialsAddPageModule' },
  { path: 'materials-view', loadChildren: './materials/materials-view/materials-view.module#MaterialsViewPageModule' },
  { path: 'product-add', loadChildren: './materials/materials-add/product-add/product-add.module#ProductAddPageModule' },
  { path: 'evidence', loadChildren: './evidence/evidence.module#EvidencePageModule' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
